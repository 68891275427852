import Q1ThumbNail from "../../assets/test/test1/thumbnail.png";
import Type0 from "../../assets/test/test1/1.png";
import Type1 from "../../assets/test/test1/2.png";
import Type2 from "../../assets/test/test1/3.png";
import Type3 from "../../assets/test/test1/4.png";

/**
 * 인터페이스 설명
 * {
 *    id: number -------------------- 테스트 식별 아이디
 *    categoryId: number ------------ 카테고리 아이디 (data.js 파일에 있습니다.)
 *    thumbnail: string ------------- 카드 썸네일 입니다. 위에서 import 합니다.
 *    title: string ----------------- 테스트 제목입니다.
 *    subTitle: string -------------- 테스트 부 제목입니다.
 *    description: string ----------- 테스트 설명 입니다.
 *    totalQuestion: number --------- 총 질문 개수 입니다. (없어도 무방, 현재 사용하지 않음)
 *    kakaoShareId: number ---------- 카카오 공유 템플릿 ID
 *    shareImage: string ------------ 카카오 공유 이미지
 *    subjectQuestion: {
 *        subject : string ----------------- 주제 (사용 하지 않음)
 *        shortLabel: string --------------- 라벨 (사용 하지 않음)
 *        positiveResult: string ----------- + 워딩 (사용 하지 않음)
 *        negativeResult: string ----------- - 워딩  (사용 하지 않음)
 *        qeustions: {
 *            no: number -------------------------- 문제 번호
 *            content: string --------------------- 질문
 *            answer: {
 *                constents: string --------------------------- 답변
 *                value: 1 | -1 ------------------------------- 값
 *            }
 *        }[] <----배열이라는 뜻
 *    }[]
 *    result: {
 *        type: string -------------- 결과 type,
 *        const: number ------------- 결과 카운트 (결과 식별자로 사용)
 *        image: string ------------- 결과 이미지 입니다. 위에서 import 합니다.
 *        description: string ------- 결과 설명 입니다.
 *    }[]
 * }
 */
export const test1 = {
  id: 1,
  categoryId: 1,
  thumbnail: Q1ThumbNail,
  title: "부모 조바심 - 저항 유형 테스트",
  subTitle: "답답함을 느끼는 순간과, 아이에게 미치는 영향",
  description: `조심하려고 해도, 자꾸만 울컥한다면.\n\n부모의 저항감은 높은 확률로 아이에게 그대로 전이됩니다.\n내 민감도를 체크하고, 아이의 학습 전략을 세워봅시다.\n\n14개의 질문을 통해, 4개의 유형 중\n본인에게 가장 적합한 결과지를 받아보게 됩니다.`,
  totalQuestion: 14,
  kakaoShareId: 102380,
  shareImage:
    "https://i.ibb.co/NFWGkrr/pexels-nh-kim-739233225-18562520.jpg",
  subjectQuestion: [
    {
      subject: "편안함의 조건",
      shortLabel: "편안함의 조건",
      positiveResult: "내 아이가 남들보다 잘해야만 한다",
      negativeResult: "내 아이가 속한 그룹이 중요하다.",
      questions: [
        {
          no: 1,
          content: "내 삶을 한번 되돌아보면,\n나는 이런 환경에서 '그나마' 더 성과가 좋았어.",
          answer: [
            {
              contents: "내가 속한 모임 내에서, 내가 상위권일 때. (공부 외적으로도)\n학교, 회사, 동아리... 내가 남들보다 잘해야 신이 나.",
              value: 1,
            },
            {
              contents: "내가 상위권은 아니더라도, 배울 것이 많을 때.\n내가 속한 그룹 자체에 뛰어난 이들이 많아야 좋아.",
              value: -1,
            },
          ],
        },
        {
          no: 2,
          content: "조금 얌체처럼 보일지도 모르겠지만...\n나는 솔직히 이런 이미지에 더 가까운 것 같아.",
          answer: [
            {
              contents: "공부, 외모관리... 이런 분야에 있어서 \n티 안내고 혼자 몰래 노력한 다음, 결과물을 갖고 등장!",
              value: 1,
            },
            {
              contents: "내 고민을 다 털어놓고, 가끔은 과하게 징징대지. \n내 상황이 드러나도 상관없어. 오히려 내게 도움될지도?",
              value: -1,
            },
          ],
        },
        {
          no: 3,
          content: "내가 더 민감하게 반응하는 상황은?\n나도 모르게 울컥하는 순간.",
          answer: [
            {
              contents: "별로 친하지도 않은 사람인데, \n갑자기 내게 '자녀교육'을 가르치려고 하는 순간.",
              value: 1,
            },
            {
              contents: "별로 친하지도 않은 사람인데, \n갑자기 내 거주지역, 자녀의 학교, 학원 등을 비꼬는 순간...",
              value: -1,
            },
          ],
        },
        {
          no: 4,
          content: "인스타그램 피드를 내리는데 괜히 조바심이 올라와.\n다음 중 어떤 상황에서?",
          answer: [
            {
              contents: "홈스쿨링, 독학 등... \n별로 대단해보이지 않는 방법으로 큰 성과를 이룬 사례.",
              value: 1,
            },
            {
              contents: "내가 꿈꿀 수 없는 무언가. \n초고액 과외를 시키는 엄마, 고액 유학길의 연예인 자녀...",
              value: -1,
            },
          ],
        },
        {
          no: 5,
          content: "곰곰이 생각해보면 나는 이런 성향인 것 같다.\n정답은 없잖아? 취향이지",
          answer: [
            {
              contents: "겉으로 보기엔 대단하지 않아도, 내가 만족하는 삶.\n예를 들어, 수입이나 명성은 없어도 스트레스가 없다거나.",
              value: 1,
            },
            {
              contents: "스트레스가 있어도 '내세울만한 것'은 있어야지. \n결국 인간은 사회적인 동물 아니겠어?",
              value: -1,
            },
          ],
        },
        {
          no: 6,
          content: "내 일, 아이의 학업... 잘 풀릴 땐, 주변에 자랑을 흘렸다. \n그리고 하향세인 지금.",
          answer: [
            {
              contents: "조용히, 재도약을 준비하며 잠을 줄인다. \n한 번 해봤으니 자신감도 있고.",
              value: 1,
            },
            {
              contents: "숨지 않고, 결과가 기대에 못 미치는 '지금'을 이야기 한다. \n뭐, 징징댈수도 있고. 밥이나 얻어먹을 핑계 하하.",
              value: -1,
            },
          ],
        },
        {
          no: 7,
          content: "내 주변의 사람들은, \n솔직히 나의 이러한 모습에 끌리는 것 같다.",
          answer: [
            {
              contents: "그럴싸한 성과를 그럭저럭 잘 내니까. \n아이가 공부를 잘 한다거나, 새로운 도전을 한다거나.",
              value: 1,
            },
            {
              contents: "무엇보다 대화가 잘 통하니까. \n시덥잖은 이야기로도 두시간은 떠들 수 있으니까.",
              value: -1,
            },
          ],
        },
      ],
    },
    {
      subject: "드러냄",
      shortLabel: "스스로",
      positiveResult: "내적주체",
      negativeResult: "외적주체",
      questions: [
        {
          no: 15,
          content: "내가 아이에게 기대하는 것들을 떠올려보자. \n그리고, 아이가 이를 안하려고 하거나 못할 때.",
          answer: [
            {
              contents: "이걸 왜 이렇게 못하지?\n나는 이렇게까지 어려워하지 않았던 것 같은데.",
              value: 1,
            },
            {
              contents: "전문가가 붙어야 하려나?\n아이에게 더 잘 맞는 학습방법이나 환경이 있으려나...",
              value: -1,
            },
          ],
        },
        {
          no: 16,
          content: "새로운 것들이 자꾸만 나온다.\nChatGPT가 어쩌고... 디지털 문해력이 중요하다네 또...",
          answer: [
            {
              contents: "일단 내가 먼저 조금이라도 익숙해져야 할 것 같다. \n내가 알아야 아이에게 알려주지.",
              value: 1,
            },
            {
              contents: "고려해야 할 것들이 너무 많다. 따라가기 벅차.\n또 학원을 보내야하나?",
              value: -1,
            },
          ],
        },
        {
          no: 17,
          content: "내 자녀의 성적을 올릴 수 있다면 나는... \n만약 선택지가 주어진다면.",
          answer: [
            {
              contents: "내 글쓰기나 몰입 능력이 올라가는 만큼, \n아이의 성적을 올려주세요. 글쓰기 연습할게요. ",
              value: 1,
            },
            {
              contents: "전국 팔도를 돌아다니더라도, 내가 더 피곤해져도 좋아요. \n내 아이에게 더 잘 맞는 강사를 찾아다닐게요.",
              value: -1,
            },
          ],
        },
        {
          no: 18,
          content: "교육-학원 시장은, 학부모들을 과하게 겁주는 것 같다.\n이에 노출되다보니 나는...",
          answer: [
            {
              contents: "상대적으로 잘 흔들리지 않는 것 같다. \n'또 저러네'라거나, 회의적인 시각.",
              value: 1,
            },
            {
              contents: "남들보다 과하게 불안해하는 것 같다.\n 잘 모르니까 그런 것 같기도.",
              value: -1,
            },
          ],
        },
        {
          no: 19,
          content: "내 아이에게 바라는 것들.\n솔직히 나는 갖고 있는가?(주도적 학습능력 등)",
          answer: [
            {
              contents: "어느정도는 갖고 있다고 할 수 있지.\n애초에 내가 아예 못하는데 강요할 생각 없어.",
              value: 1,
            },
            {
              contents: "나는 잘 못하지만, 아이는 재능이 있는 것 같아.\n재능을 키워주고, 환경 조성이 내 목적.",
              value: -1,
            },
          ],
        },
        {
          no: 20,
          content: "갑자기 특정 학습법, 학습지, 학원 등이 유행을 타네\n처음 드는 생각은?",
          answer: [
            {
              contents: "일단 경계한다. \n바로 내 아이에게 시키기보다는, 보수적으로.",
              value: 1,
            },
            {
              contents: "솔직히 호들갑에는 이유가 있다.\n유행을 탄다면, 무리가 가지 않는 한 시켜본다.",
              value: -1,
            },
          ],
        },
        {
          no: 21,
          content: "기존의 학습 능력치는 더 이상 중요하지 않다던데.\n이제는 암기력은 의미 없고, 창의성이 우선시 된다거나.",
          answer: [
            {
              contents: "약간의 변화는 있어도,\n 앞으로도 기존의 능력치들이 강조될거야.",
              value: 1,
            },
            {
              contents: "확실히 달라질 것 같아. \n 기존 학업 성적은 더 이상 중요하지 않을거야.",
              value: -1,
            },
          ],
        },
      ],
    },
  ],
  result: [
    {
      type: "",
      count: 0,
      image: Type0,
      description:
        "",
    },
    {
      type: "",
      count: 1,
      image: Type2,
      description:
        "",
    },
    {
      type: "",
      count: 2,
      image: Type1,
      description:
        "",
    },
    {
      type: "",
      count: 3,
      image: Type3,
      description:
        "",
    },
  ],
};
