import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../main/component/Card";
import { allTest } from "../../data/data";
import { useEffect, useRef, useState } from "react";
import KakaoImg from "../../assets/image/kakaotalk_shareing.png";
import DisplayAds from "../../components/DisplayAdsFlex";

export const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const questionId = useRef(undefined);
  const [data, setData] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [showTaost, setShowTaost] = useState(false);
  const resultCount = useRef(undefined);
  const [overlayVisible, setOverlayVisible] = useState(true);


  useEffect(() => {
    const id = location.pathname.split("/")[2];
    const count = location.pathname.split("/")[4];
    questionId.current = id;
    resultCount.current = count;
    if (allTest) {
      setData(allTest.find((item) => `${item.id}` === `${id}`));
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setResult(
        data.result.find((item) => `${item.count}` === `${resultCount.current}`)
      );
    }
  }, [data]);

  if (!data || !result) return null;

  const handleOverlayClick = () => {
    setOverlayVisible(false); // 오버레이 숨기기
    window.open('https://link.coupang.com/a/bAWu5J', '_blank'); // 새 창에서 쿠팡 웹사이트 열기
  };
  


  return (
    <TestContainer>
      <ResultImg src={result.image} />
      {overlayVisible && (
  <Overlay onClick={handleOverlayClick}>
    <CloseButton onClick={() => setOverlayVisible(false)}>X</CloseButton>
    <OverlayImage src="https://i.ibb.co/1JpCB8f/image.jpg" alt="Overlay Promotional Image" />
    <BannerText>쿠팡 방문하고 검사 결과 보기 </BannerText>
    <SmallText>쿠팡 파트너스의 일환으로 수수료를 얻을 수 있습니다.</SmallText>
  </Overlay>
)}


      
      <ResultType>{result.type}</ResultType>
      <Description>{result.description}</Description>

      <div
        style={{
          // width: "100%",
          marginTop: 36,
          fontSize: 19,
          fontWeight: 400,
        }}
      >
        공유하기
      </div>
      <ShareContainer>
        {data.kakaoShareId && (
          <ShareItem
            onClick={() => {
              window.Kakao.Share.sendDefault({
                objectType: "feed",
                content: {
                  title: data.title,
                  description: data.description,
                  imageUrl: data.shareImage,
                  link: {
                    mobileWebUrl: `http://types.justrelaxandflow.com/test-main/${data.id}`,
                    webUrl: `http://types.justrelaxandflow.com/test-main/${data.id}`,
                  },
                },
                buttons: [
                  {
                    title: "유형검사 시작",

                    link: {
                      mobileWebUrl: `http://types.justrelaxandflow.com/test-main/${data.id}`,
                      webUrl: `http://types.justrelaxandflow.com/test-main/${data.id}`,
                    },
                  },
                  {
                    title: "공유된 결과보기",

                    link: {
                      mobileWebUrl: `http://types.justrelaxandflow.com${location.pathname}`,
                      webUrl: `http://types.justrelaxandflow.com${location.pathname}`,
                    },
                  },
                ],
              });
            }}
          >
            <img className="share__icon" src={KakaoImg} />
            <div className="share__title">카카오톡</div>
          </ShareItem>
        )}
   <CopyToClipboard
          className="Toram"
          text={`http://types.justrelaxandflow.com/test-main/${data.id}`}
          onCopy={() => {
            setShowTaost(true);

            setTimeout(() => {
              setShowTaost(false);
            }, 1500);
          }}
        >
          <ShareItem>
            <div
              className="share__icon"
              style={{
                border: "1px solid gray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              URL
            </div>
            <div className="share__title">링크복사</div>
          </ShareItem>
        </CopyToClipboard>

        <ShareItem
          onClick={() => {
            const linkToGo = `https://cafe.naver.com/writefuture/4582`;
            window.location.href = linkToGo;
          }}
        >
          <div
            className="share__icon"
            style={{
              border: "1px solid gray",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            ETC
          </div>
          <div className="share__title">아직아이</div>
        </ShareItem>
      </ShareContainer>

      <StartBtn
        style={{ marginTop: 24 }}
        onClick={() => {
          navigate(`/test-main/${questionId.current}`);
        }}
      >
        다시하기
      </StartBtn>

      <TestList>
        {allTest
          .filter(
            (item) => item.categoryid === data.categoryId && item.id !== data.id
          )
          .map((item, index) => (
            <FlexColumn key={index}>
              {index % 3 === 0 && <DisplayAds />}
              <Card key={index} cardItem={item} />
            </FlexColumn>
          ))}
      </TestList>
      <ToastMsg className={showTaost ? "toast__show" : ""}>
        링크 복사를 완료하였습니다.
      </ToastMsg>
    </TestContainer>
  );
};

const TestContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ResultImg = styled.img`
  width: 100%;  // 필요에 따라 화면 폭에 맞게 이 값을 조정하세요
  height: auto; // 이렇게 하면 이미지의 비율이 유지됩니다
  margin: 24px auto;
  max-width: 600px; // 선택사항: 매우 넓은 화면에서 이미지가 너무 크게 보이지 않도록 최대 너비를 설정할 수 있습니다
  display: block; // margin auto가 중앙 정렬에 효과적으로 적용되도록 합니다
  border-radius: 15px; // 이 값을 조정하여 모서리의 둥근 정도를 변경할 수 있습니다

`;

const ResultType = styled.div`
  width: 100%;
  text-align: center;

  color: var(--light-text01, #1f2022);

  /* heading/18-sb */
  font-family: 'GyeonggiBatang';

  font-size: 24px;
  font-style: normal;
  : 400;
  
`;
const Description = styled.pre`
  width: 100%;
  padding: 0 24px;
  margin-top: 10px;
  color: var(--light-text01, #1f2022);

  word-break: break-all;
  white-space: break-spaces;

  /* heading/18-sb */
  font-family: 'GyeonggiBatang';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  
`;

const StartBtn = styled.button`
  width: 222px;
  height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  border: 0;

  background-color: #133337;

  margin: 0 auto;

  font-family: 'GyeonggiBatang';
  font-size: 15px;
  font-weight: 400;
  color: white;

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    background-color: ##130a3e;
  }
`;

const TestList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 48px;
  padding-bottom: 24px;
`;

const ShareContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 10px;
`;

const ShareItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  .share__icon {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    border-radius: 50%;
  }
  .share__title {
    font-size: 14px;
  }
`;

const ToastMsg = styled.div`
  position: fixed;

  width: max-content;
  height: 40px;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 20px;
  padding: 12px 24px;

  line-height: 1;
  font-size: 15px;
  font-weight: 400;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  transition: all 0.2s ease-in;

  &.toast__show {
    bottom: 24px;
  }
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 22%;
  width: 100%;
  height: 78%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white; // Ensure the 'X' is visible on a dark background
`;

const ActionButton = styled.button`
  padding: 15px 30px;
  border: none;
  background-color: #f96d41;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0 4px #b45437;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e95c30;
    box-shadow: 0 2px #b45437;
    transform: translateY(2px);
  }

  &:active {
    transform: translateY(4px);
    box-shadow: 0 2px #a54326;
  }
`;

const SmallText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: white;
  text-align: center;
`;


const BannerText = styled.div`
  color: white;
  font-size: 24px;
  border: 1px solid white; // 흰색의 얉은 선 추가
  border-radius: 8px; // 모서리를 살짝 곡선으로 만듦
  padding: 10px 20px; // 텍스트와 경계 사이에 여유 공간을 주어 버튼처럼 보이도록 함
  display: inline-block; // 선이 텍스트 내용에 맞게 조절되도록 디스플레이 속성 변경
  cursor: pointer; // 마우스 오버 시 포인터 모양으로 변경하여 클릭 가능한 요소임을 표시
  background-color: transparent; // 배경색을 투명하게 설정
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // 경계에 그림자 효과를 추가하여 입체감을 줌
`;



const OverlayImage = styled.img`
  width: 40%; // 이미지 크기 조정
  max-width: 600px; // 최대 너비 설정
  height: auto; // 이미지의 원래 비율 유지
  display: block; // 이미지 블록으로 설정
  margin-top: 40px; // 위쪽에 30px의 여백을 주어 아래로 조금 이동
  margin-Right: -150px; // 왼쪽 여백을 추가하여 이미지를 오른쪽으로 이동
  margin-bottom: -40px; // 이미지 아래쪽 여백을 줄임 (기존에는 더 클 수 있음)


`;